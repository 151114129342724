.nerve-app__nav-container {
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: center;
}

.nerve-app__nav-container img {
  display: flex;
  height: 25px;
}

.nerve-app__nav-container a button {
  margin-left: 25px;
  background-color: white;
  color: black;
  height: 32px;
  width: 100px;
  border-radius: 5px;
  outline: none;
  border: none;
}

.nerve-app__nav-container a button:hover {
  transform: scale(1.04);
  transition: 0.2s;
  cursor: pointer;
}

.nerve-app__list-container {
  list-style: none;
  display: flex;
  margin-left: auto;
}

.nerve-app__list-container i:hover {
  cursor: pointer;
  color: #8261fb;
  transition: 0.2s;
}

.nerve-app__list-container a {
  text-decoration: none;
  color: black;
  padding: 10px 25px;
  font-weight: bold;
}

/* .nerve-app__list-container li:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: #00b7f8;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  transition: all 0.2s ease-in-out 0s;
}

.nerve-app__list-container li:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
} */

.nerve-app__list-container a:hover {
  transform: scale(1.1);
  transition: 0.2s;
  color: #8261fb;
}

.nerve-app__nav-launch-btn {
  text-decoration: none;
}
