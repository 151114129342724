.nerve-app__main-container {
  padding: 0 120px;
  height: 100vh;
}

.nerve-app__content-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 62vh;
}

.nerve-app__content-container > div {
  width: 50%;
  font-family: Geneva, Verdana, sans-serif;
}

.nerve-app__content-container img {
  height: 50vh;
}

.nerve-app__content-container h1 {
  font-size: 50px;
}
.nerve-app__button-wrapper {
  display: flex;
}

.nerve-app__content-button {
  margin-right: 10px;
  margin-top: 20px;
  background: #8261fb;
  color: white;
  height: 44px;
  width: 140px;
  border-radius: 5px;
  outline: none;
  border: none;
  font-size: 16px;
}
#trade {
  background: white;
  color: black;
}

.nerve-app__content-button:hover {
  transform: scale(1.04);
  transition: 0.2s;
  cursor: pointer;
}

.nerve-app__content-button a {
  text-decoration: none;
  color: white;
}

#trade a {
  color: black;
}

.nerve-app__stats-container {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 0;
  padding: 0;
  margin-top: 5vh;
  flex-wrap: wrap;
}

.nerve-app__stats-container li {
  font-weight: bold;
  display: flex;
  align-items: center;
}

.nerve-app__stats-container h1 {
  color: #8261fb;
  font-size: 40px;
}

.nerve-app__stats-icon {
  font-size: 40px;
}

.nerve-app__stats-right {
  padding-left: 20px;
  margin-left: 32px;
  display: flex;
  align-items: center;
}

.nerve-app__stats-right span {
  margin-left: 12px;
  width: 100px;
}

nerve-app__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: 0px) and (max-width: 760px) {
  .nerve-app__hide-element {
    display: none;
  }
  .nerve-app__content-container h1 {
    font-size: 40px;
  }

  .nerve-app__main-container {
    padding: 0px 40px;
  }

  .nerve-app__content-container > div {
    width: 100%;
  }

  .nerve-app__button-wrapper {
    justify-content: center;
  }

  .nerve-app__content-container {
    text-align: center;
    justify-content: flex-start;
    height: 100%;
  }

  .nerve-app__stats-container li {
    padding: 20px;
  }

  .nerve-app__stats-right {
    border-left: none !important;
    margin-left: 0px;
  }
}
