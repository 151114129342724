.footer__socials {
  list-style-type: none;
  position: absolute;
  bottom: 0;
  left: 0;
  padding-bottom: 20px;
}

.footer__socials i {
  font-size: 2rem;
  transition: ease-in 0.1s;
  padding: 7px;
}

.footer__socials i:hover {
  cursor: pointer;
  transform: scale(1.1);
  color: #8261fb;
}

.footer__socials a {
  text-decoration: none;
  color: inherit;
}
